/* eslint-disable no-shadow */

import {
  format,
  parseISO,
} from 'date-fns';

const state = () => ({
  nutritionSums: {},
  selectedDate: format(new Date(), 'yyyy-MM-dd'),
});

const getters = {
  nutritionSums: (state) => state.nutritionSums,
  // Convert date with users timezone taken into account.
  selectedDate: (state) => parseISO(state.selectedDate),
};

const mutations = {
  reset() {
    state.nutritionSums = null;
    state.selectedDate = null;
  },
  setNutritionSums(state, values) {
    const sums = {};
    Object.entries(values).map(([name, value]) => {
      sums[name] = this.$app.$helper.convertNumber(value);
      return sums;
    });
    state.nutritionSums = sums;
  },
  selectDate(state, date) {
    // Store date without timezone as it's converted to a string and thus use UTC instead of GTM.
    state.selectedDate = format(date, 'yyyy-MM-dd');
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
