import Vue from 'vue';
import Router from 'vue-router';
import { auth, analytics } from '@/firebase';

import i18n from '@/i18n';
import store from '@/store';
import UserProfile from '@/models/user-profile';

import accountRoutes from './account';
import onboardingRoutes from './onboarding';
import userRoutes from './user';

const Calculator = () => import('@/views/Calculator');
const Dashboard = () => import('@/views/Dashboard');
const ExpiredReport = () => import('@/views/ExpiredReport');
const NotFound = () => import('@/views/NotFound');
const Report = () => import('@/views/Report');
const Shared = () => import('@/views/Shared');
// Food Routes.
const FoodList = () => import('@/views/Food/FoodList');
const FoodDetails = () => import('@/views/Food/FoodDetails');
// Meal Routes.
const MealDetails = () => import('@/views/Meal/MealDetails');
const TemplateDetails = () => import('@/views/Meal/TemplateDetails');

export const welcomePath = { name: 'welcome' };
export const onboardingPath = { name: 'diagnosis' };
export const loggedinPath = { name: 'dashboard' };
export const loggedOutPath = { name: 'login' };

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      beforeEnter: (to, from, next) => {
        auth.onAuthStateChanged((user) => {
          if (user) {
            next({ ...loggedinPath, query: to.query });
          } else {
            next({ ...welcomePath, query: to.query });
          }
        });
      },
    },
    ...accountRoutes,
    ...onboardingRoutes,
    ...userRoutes,
    {
      path: '/foods',
      component: {
        render(c) {
          return c('router-view');
        },
      },
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: ':mealType/:action?',
              name: 'FoodList',
              component: FoodList,
              props: true,
              meta: {
                title: i18n.t('foodList.title'),
              },
            },
            {
              path: ':mealType/:id',
              name: 'FoodDetailsView',
              component: FoodDetails,
              props: true,
              meta: {
                showNavigation: true,
                title: i18n.t('food.title'),
              },
            },
            {
              path: ':mealType/add/:id',
              name: 'addFoodToType',
              component: FoodDetails,
              props: true,
              meta: {
                showNavigation: true,
                title: i18n.t('food.title'),
              },
            },
          ],
        },
      ],
    },
    {
      path: '/meals/create/:mealType/:id?',
      name: 'MealDetails',
      component: MealDetails,
      meta: {
        title: i18n.t('meal.title'),
      },
      props: true,
    },
    {
      path: '/template/:date',
      name: 'TemplateDetails',
      component: TemplateDetails,
      props: true,
    },
    {
      path: '/meals/add/:id',
      name: 'addFoodToMeal',
      component: FoodDetails,
      props: (route) => ({
        id: route.params.id,
        showDateSelect: false,
        showMealSelect: false,
      }),
      meta: {
        title: i18n.t('food.title'),
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        title: i18n.t('dashboard.title'),
        requiresAuth: true,
        showNavigation: true,
      },
    },
    {
      path: '/calculator',
      name: 'calculator',
      component: Calculator,
      props: true,
      meta: {
        title: i18n.t('calculator.title'),
        requiresAuth: true,
        showNavigation: true,
      },
    },
    {
      name: 'report',
      path: '/report',
      component: Report,
      meta: {
        title: i18n.t('report'),
        requiresAuth: true,
        showNavigation: true,
      },
    },
    {
      name: 'sharedReport',
      path: '/report/:token',
      component: Shared,
      props: (route) => ({
        token: route.params.token,
      }),
      meta: {
        title: i18n.t('sharedReport'),
        showNavigation: false,
      },
    },
    {
      name: 'expiredReport',
      path: '/expired-report',
      component: ExpiredReport,
      meta: {
        title: i18n.t('expiredReport'),
        showNavigation: false,
      },
    },
    {
      name: '404',
      path: '*',
      component: NotFound,
      meta: {
        title: i18n.t('404.title'),
        requiresAuth: false,
      },
    },
  ],
});

/**
 * Redirect logged in users to the dashboard and avoid them from accessing
 * the login/register routes.
 *
 * Anonymous users are redirect to the login screen instead.
 */
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;

  auth.onAuthStateChanged(async (user) => {
    const routeRequiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    // Pass through if protected route and user is authenticated, otherwise login screen.
    if (routeRequiresAuth && user) {
      next();
    } else if (routeRequiresAuth && !user) {
      next(loggedOutPath);
    // Send user to dashboard if logged in and tries to access login or register screens.
    } else if (user && ['login', 'register'].includes(to.name)) {
      next(loggedinPath);
    // Make sure to always call next to terminate the route request.
    } else {
      next();
    }
  });
});

/**
 * Check if onboarding is completed by the user.
 *
 * If the user tries to access an onboarding route after the onboarding is completed,
 * he will be redirected to the dashboard instead.
 */
router.beforeEach((to, from, next) => {
  auth.onAuthStateChanged(async (user) => {
    const isOnboardingRoute = to.matched.some((record) => record.path === '/onboarding');
    if (user) {
      const userProfile = await new UserProfile(user.uid).get();
      if (userProfile.diagnosisName) {
        store.commit('user/setDiagnosisName', userProfile.diagnosisName);
        store.commit('user/setNutritionLimits', userProfile.nutritionLimits);
        analytics.setUserProperties({
          user_diagnosis: userProfile.diagnosisName,
        });
      }

      if (!isOnboardingRoute && !userProfile.onboardingCompleted) {
        next(onboardingPath);
      } else if (isOnboardingRoute && userProfile.onboardingCompleted) {
        next(loggedinPath);
      }
    }
    next();
  });
});

/**
 * Set the current page class name on the body,
 */
router.beforeEach((to, _, next) => {
  document.body.classList = `page--${to.name}`;
  next();
});

router.afterEach((to, from, failure) => {
  if (!failure) {
    analytics.logEvent('screen_view', {
      screen_name: to.params?.title ?? to.meta.title,
    });
  }
});

export default router;
