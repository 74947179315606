<template>
  <div :class="{ loadingContainer: true, fadeout: !isLoading }">
    <div class="loadingContainer__content">
      <img
        src="../../../assets/icons/splashscreen-logo.svg"
        class="rotate"
      >
      <h3>LoProGramm</h3>
    </div>
    <div class="bottom-branding">
      <branding />
    </div>
  </div>
</template>

<script>
import Branding from '@/components/Branding';

export default {
  name: 'LoadingScreen',
  components: {
    Branding,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    enterApplication: { type: Function, default: () => {} },
  },
};
</script>

<style lang="scss">
.loadingContainer {
  background-image: url('../../../assets/splash-screen.png');
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  &::before {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }
  &__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
      font-size: 24px;
      color: #fff;
      text-shadow: 0 0 14px rgba(0, 0, 0, 0.5);
      margin: 1rem auto;
    }
  }
}

.bottom-branding {
  width: 100%;
  z-index: 9;
  position: absolute;
  bottom: 35px;
  left: 0;
  right: 0;
}
.fadeout {
  animation: fadeout 0.3s forwards;
}

.rotate {
  animation: spin-scale 4s linear infinite;
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes spin-scale {
    50%{
      transform: rotate(360deg) scale(1.25);
    }
    100% {
      transform: rotate(720deg) scale(1);
    }
}

</style>
