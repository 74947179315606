import i18n from '@/i18n';

const Diagnosis = () => import('@/views/Onboarding/Diagnosis');
const Values = () => import('@/views/Onboarding/Values');
const Welcome = () => import('@/views/Onboarding/Welcome');

export default [
  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome,
    meta: {
      title: i18n.t('onboarding.title'),
    },
  },
  {
    path: '/onboarding',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'diagnosis',
        name: 'diagnosis',
        component: Diagnosis,
        meta: {
          title: i18n.t('diagnosis.title'),
        },
      },
      {
        path: 'values',
        name: 'values',
        component: Values,
        meta: {
          title: i18n.t('values.title'),
        },
      },
    ],
  },
];
