import { db } from '@/firebase';
import store from '@/store';

export default class UserProfile {
  constructor(userId) {
    this.profile = {};
    this.userId = userId;
    this.reference = db.collection('userProfiles').doc(userId);
  }

  /**
   * Get a user's profile.
   *
   * @param {Integer} userId
   */
  async get() {
    const userProfile = await this.reference.get();
    this.profile = userProfile.exists ? userProfile.data() : {};
    return this.profile;
  }

  /**
   * Update a user's profile.
   *
   * @param {Integer} userId
   * @param {Object} data
   */
  async update(data) {
    let saveData = data;

    if (data.savedNutritionLimits) {
      store.commit('user/setSavedNutritionLimits', data.savedNutritionLimits);

      const lastSavedNutritionLimits = Object.keys(data.savedNutritionLimits).sort((a, b) => {
        const x = a.split('-').join('');
        const y = b.split('-').join('');
        return x > y ? 1 : x < y ? -1 : 0;
      }).pop();

      const { diagnosisName, ...nutritionLimits } =
        data.savedNutritionLimits[lastSavedNutritionLimits];

      store.commit('user/diagnosisName', diagnosisName);
      store.commit('user/setNutritionLimits', nutritionLimits);

      saveData = {
        diagnosisName,
        nutritionLimits,
        savedNutritionLimits: data.savedNutritionLimits,
      };
    }

    return this.reference.update(saveData);
  }

  /**
   * Create a user's profile.
   *
   * @param {Integer} userId
   */
  create(data) {
    return this.reference.set(data);
  }
}
