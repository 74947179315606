import { de } from 'date-fns/locale';
import i18n from '@/i18n';
import store from '@/store';
import {
  compareDesc,
  isSameMonth,
  max,
  min,
  format,
  parseISO,
} from 'date-fns';

const formatRelativeLocale = {
  lastWeek: 'dd.MM.yyyy',
  yesterday: `'${i18n.t('yesterday')}'`,
  today: `'${i18n.t('today')}'`,
  tomorrow: `'${i18n.t('tomorrow')}'`,
  nextWeek: 'dd.MM.yyyy',
  other: 'dd.MM.yyyy',
};

const dateLocale = {
  ...de,
  formatRelative: (token) => formatRelativeLocale[token],
};

const getNutritionUnit = (nutrition) => {
  let unit = '';
  switch (nutrition) {
    case 'carbs':
    case 'fat':
    case 'protein':
      unit = 'g';
      break;
    case 'isoleucine':
    case 'leucine':
    case 'lysine':
    case 'methionine':
    case 'phe':
    case 'tryptophan':
    case 'tyrosine':
    case 'valine':
      unit = 'mg';
      break;
    case 'calories':
      unit = 'kcal';
      break;
    default:
      break;
  }
  return unit;
};

const getDiagnosisKey = (diagnosis) => {
  const name = diagnosis || store.getters['user/diagnosis'];
  let key = '';
  switch (name) {
    case 'PKU':
      key = 'phe';
      break;
    case 'TYR 1':
      key = 'tyrosine';
      break;
    default:
      break;
  }
  return key;
};

const getDiagnosisValues = () => {
  const name = store.getters['user/diagnosis'];
  const nutritionKey = getDiagnosisKey(name);
  const nutritionSums = store.getters['dayplan/nutritionSums'];
  const nutritionValues = store.getters['user/nutritionValues'];
  const unit = getNutritionUnit(nutritionKey);
  return {
    name,
    nutritionKey,
    used: nutritionSums[nutritionKey] || 0,
    limit: nutritionValues.diagnosis || 0,
    unit,
  };
};

const getAbbreviation = (enzyme) => {
  const abbr = {
    isoleucine: 'ISO',
    leucine: 'LEU',
    lysine: 'LYS',
    methionine: 'MET',
    phe: 'PHE',
    tryptophan: 'TRP',
    tyrosine: 'TYR',
    valine: 'VAL',
    protein: 'Eiweiß',
  };
  return abbr[enzyme] || '';
};

// Remove special chars from category name to allow match with image filename.
const sanitizeCategoryName = (value = '') => {
  let str = value.toLowerCase();
  str = str.replace(/ä/g, 'ae');
  str = str.replace(/ö/g, 'oe');
  str = str.replace(/ü/g, 'ue');
  str = str.replace(/ß/g, 'ss');
  str = str.replace(/&/g, 'und');
  str = str.replace(/ /g, '-');
  str = str.replace(/--/g, '-');
  str = str.replace(/\./g, '');
  str = str.replace(/,/g, '');
  str = str.replace(/\(/g, '');
  str = str.replace(/\)/g, '');
  return str;
};

const checkAsmIndication = (foodTitle) => {
  let indicationFound = '';
  const indications = ['GA', 'HOM', 'LEU', 'MSUD', 'OS', 'PKU', 'TYR', 'UCD'];
  indications.forEach((indication) => {
    if (foodTitle.includes(indication)) {
      indicationFound = indication;
    }
  });
  return sanitizeCategoryName(indicationFound);
};

const getNutritionLimits = (userProfile, date, shouldSetLimit = true) => {
  let nutritions = {};
  const dateISO = parseISO(date);
  const savedLimits = userProfile?.savedNutritionLimits || store.getters['user/savedNutritionLimits']; // get nutr saved under diagnosis

  if (savedLimits) {
    // @todo Simplify this code; it still assumes that multiple nutrition limits
    //   can be saved for the same date, but this has been corrected to overwrite
    //   the limits on the same date. Intended logic:
    //   - For the given date, retrieve the nutrition limits that were set on
    //     that date or the ones that were set before that date (as they are
    //     still applicable on the date then).
    //   - Convert ISO date strings into numbers (by removing hyphens) and just
    //     sort and compare them as numbers.
    //   - Sort the history of nutrition limits reverse chronologically and
    //     return early as soon as we found the matching value; so as to avoid
    //     looping over the entire history for recent dates.
    //   - Move the shouldSetLimit behavior into calling code.
    const limitsArray = Object.keys(savedLimits); // get nutr dates (saved keys)
    if (limitsArray.length > 1) { // if more than one nutr is saved under key
      const sameMonthLimits = [];
      limitsArray.forEach((limit) => {
        // convert date to ISO, so I don't need converting everytime I need to compare in ISO
        const limitISO = parseISO(limit);
        // check if any limit is saved in the month, and did not come after the selectedSate
        if (isSameMonth(dateISO, limitISO) && compareDesc(limitISO, dateISO) > -1) {
          sameMonthLimits.push(limitISO);
        }
      });
      // if there're limits saved in selectedDate's month, find the max; set nutritions if found
      if (sameMonthLimits && sameMonthLimits.length > 0) {
        const lastNutritionSaved = max([...sameMonthLimits]);
        if (shouldSetLimit) {
          store.commit('user/setNutritionLimits', savedLimits[format(lastNutritionSaved, 'yyyy-MM-dd')]);
        }
        nutritions = savedLimits[format(lastNutritionSaved, 'yyyy-MM-dd')];
      } else {
        // loop through saved keys, push to dateExtract if [date] comes before/equals selectedDate
        const datesExtract = [];
        limitsArray.forEach((limit) => {
          const limitISO = parseISO(limit);
          if (compareDesc(limitISO, parseISO(date)) > -1) {
            datesExtract.push(limitISO);
          }
        });
        // check for the closest to selectedDate; set nutritions
        if (datesExtract && datesExtract.length) {
          const closestDate = max(datesExtract);
          if (shouldSetLimit) {
            store.commit('user/setNutritionLimits', savedLimits[format(closestDate, 'yyyy-MM-dd')]);
          }
          nutritions = savedLimits[format(closestDate, 'yyyy-MM-dd')];
        } else {
          // if date selected is before every saved dates, check for the minimum date
          const allSavedDates = [];
          limitsArray.forEach((limit) => {
            allSavedDates.push(parseISO(limit));
          });
          const initialDate = min(allSavedDates);
          if (shouldSetLimit) {
            store.commit('user/setNutritionLimits', savedLimits[format(initialDate, 'yyyy-MM-dd')]);
          }
          nutritions = savedLimits[format(initialDate, 'yyyy-MM-dd')];
        }
      }
    } else { // if one nutr is saved under key - return its nutritions
      if (shouldSetLimit) {
        store.commit('user/setNutritionLimits', savedLimits[limitsArray[0]]);
      }
      nutritions = savedLimits[limitsArray[0]];
    }
  } else {
    nutritions = userProfile?.nutritionLimits || store.getters['user/nutritionLimits'];
  }
  if (nutritions && nutritions.diagnosisName) {
    delete nutritions.diagnosisName;
  }
  return nutritions;
};

const isNutritionKeyProtein = (diagnosis) => diagnosis.nutritionKey === 'protein';

const generateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.classList.add('chart-legend');
    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

// eslint-disable-next-line
const chartFilterByLegend = (chart, item) => {
  const { type } = chart.config;
  if (type === 'pie' || type === 'doughnut') {
    // Pie and doughnut charts only have a single dataset and visibility is per item
    chart.toggleDataVisibility(item.index);
  } else {
    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
  }
  chart.update();
};

const chartHtmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = generateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement('li');
      li.classList.add('chart-legend__item');
      li.style.color = item.fontColor;
      li.classList[item.hidden ? 'add' : 'remove']('is-hidden');

      li.onclick = () => {}; // chartFilterByLegend(chart, item)

      const boxSpan = document.createElement('span');
      boxSpan.classList.add('chart-legend__marker');
      boxSpan.style.background = item.lineDash.length
        ? `repeating-linear-gradient(to right, ${item.strokeStyle}, ${item.strokeStyle} 8px, rgba(0, 0, 0, 0) 8px, rgba(0, 0, 0, 0) 10px)`
        : item.strokeStyle;
      boxSpan.classList[item.lineDash.length ? 'add' : 'remove']('is-dashed');

      const text = document.createTextNode(item.text);

      li.appendChild(boxSpan);
      li.appendChild(text);
      ul.appendChild(li);
    });
  },
};

const debounce = (fn, delay = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { fn.apply(this, args); }, delay);
  };
};

export {
  chartHtmlLegendPlugin,
  dateLocale,
  debounce,
  getDiagnosisKey,
  getDiagnosisValues,
  getNutritionUnit,
  getAbbreviation,
  sanitizeCategoryName,
  checkAsmIndication,
  getNutritionLimits,
  isNutritionKeyProtein,
};
