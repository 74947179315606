import Vue from 'vue';
import Vant from 'vant';
import VCalendar from 'v-calendar';
import VueSessionStorage from 'vue-sessionstorage';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import LoproComponents from './components';
import theme from '@/styles/vendor/vant/theme/index.less'; // eslint-disable-line
import styles from '@/styles/index.scss';  // eslint-disable-line
import i18n from './i18n';

Vue.use(Vant);
Vue.use(VueSessionStorage);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
  locales: {
    de: { firstDayOfWeek: 2, masks: { weekdays: 'WW' } },
  },
});

Vue.config.productionTip = false;

// Register various custom components globally.
Object.entries(LoproComponents).forEach(([name, component]) => Vue.component(name, component));

// Vant does not load the icon if you parse in the SVG file path so this
// function attempts to load the SVG.
Vue.prototype.$icon = (icon) => {
  try {
    return require(`./assets/icons/${icon}.svg`); // eslint-disable-line global-require, import/no-dynamic-require
  } catch (error) {
    return null;
  }
};

Vue.prototype.$helper = {
  convertNumber: (value) => {
    if (typeof value === 'string') {
      return parseFloat(value.replace(',', '.'));
    }
    return value;
  },
};

Vue.prototype.$appName = 'Nutricia LoProGramm';
Vue.prototype.$appVersion = 'BLS 3.02';

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
});

store.$app = app;

app.$mount('#app');
