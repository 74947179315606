import i18n from '@/i18n';

import { getNutritionUnit } from '@/helper';

export const diagnoses = [
  {
    name: 'PKU',
    title: i18n.t('diagnoses.pku'),
    nutritionKey: 'phe',
    acidCategories: {
      fruits: 27,
      vegetables: 35,
      meat: 46,
      other: 50,
    },
  },
  {
    name: 'MSUD',
    title: i18n.t('diagnoses.msud'),
    nutritionKey: 'leucine',
    optionalNutritionKeys: ['isoleucine', 'valine'],
    acidCategories: {
      fruits: 35,
      vegetables: 53,
      meat: 89,
      other: 80,
    },
    isoleucine: {
      fruits: 30,
      vegetables: 30,
      meat: 55,
      other: 45,
    },
    valine: {
      fruits: 30,
      vegetables: 30,
      meat: 65,
      other: 55,
    },
  },
  {
    name: 'TYR 1',
    title: i18n.t('diagnoses.tyr1'),
    nutritionKey: 'tyrosine',
    acidCategories: {
      fruits: 25,
      vegetables: 25,
      meat: 41,
      other: 40,
    },
  },
  {
    name: 'HOM',
    title: i18n.t('diagnoses.hom'),
    nutritionKey: 'methionine',
    acidCategories: {
      fruits: 15,
      vegetables: 11,
      meat: 28,
      other: 20,
    },
  },
  {
    name: 'GA 1',
    title: i18n.t('diagnoses.ga1'),
    nutritionKey: 'lysine',
    optionalNutritionKeys: ['tryptophan'],
    acidCategories: {
      fruits: 43,
      vegetables: 53,
      meat: 80,
      other: 60,
    },
    tryptophan: {
      fruits: 10,
      vegetables: 10,
      meat: 15,
      other: 15,
    },
  },
  {
    name: 'MMA/PA',
    title: i18n.t('diagnoses.mma/pa'),
    nutritionKey: 'protein',
  },
  {
    name: 'IVA',
    title: i18n.t('diagnoses.iva'),
    nutritionKey: 'protein',
  },
  {
    name: 'UCD',
    title: i18n.t('diagnoses.ucd'),
    nutritionKey: 'protein',
  },
];

export const diagnosesWithoutCalculator = ['MMA/PA', 'IVA', 'UCD'];

/**
 * Model for performing operations with Diagnosis.
 */
export default class Diagnosis {
  /**
   * Extract the diagnosis from the list if diagnoses.
   *
   * A user's diagnosis is stored in the DB as the `name` attribute.
   *
   * @param {String} diagnosis
   */
  constructor(diagnosis) {
    Object.assign(this, diagnoses.find((value) => value.name === diagnosis));
  }

  /**
   * Generate a human readable diagnosis name.
   */
  getName() {
    return `(${this.name}) ${this.title}`;
  }

  getLabel(showUnit = true, nutritionKey) {
    const key = nutritionKey || this.nutritionKey;
    let label = i18n.t(`nutritions.${key}`);
    if (showUnit) {
      label += ` (${getNutritionUnit(key)})`;
    }
    return label;
  }

  /**
   * Return the name.
   */
  toString() {
    return this.getName();
  }
}
