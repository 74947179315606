import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import user from './user';
import dayplan from './dayplan';
import meal from './meal';
import template from './template';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    dayplan,
    meal,
    template,
  },
  state: {
    action: null,
  },
  getters: {
    action: (state) => state.action,
  },
  mutations: {
    setAction(state, value) {
      state.action = value;
    },
    // reset default state modules by looping around the initialStoreModules
    resetState() {
      this.$store.commit('user/reset');
      this.$store.commit('dayplan/reset');
      this.$store.commit('meal/reset');
      this.$store.commit('template/reset');
    },
  },
  plugins: [createPersistedState()],
});
