/* eslint-disable no-shadow */

const state = () => ({
  diagnosisName: '',
  nutritionLimits: {},
  savedNutritionLimits: {},
  useOptionalNutritionValues: false,
  lastSync: 0,
});

const getters = {
  diagnosisName: (state) => state.diagnosisName,
  nutritionLimits: (state) => state.nutritionLimits,
  savedNutritionLimits: (state) => state.savedNutritionLimits,
  useOptionalNutritionValues: (state) => state.useOptionalNutritionValues,
  lastSync: (state) => state.lastSync,
};

const mutations = {
  reset(state) {
    state.diagnosisName = null;
    state.nutritionLimits = null;
    state.savedNutritionLimits = null;
    state.useOptionalNutritionValues = false;
    state.lastSync = null;
  },
  setDiagnosisName(state, value) {
    state.diagnosisName = value;
  },
  setNutritionLimits(state, values = {}) {
    if (values && values.diagnosisName) {
      delete values.diagnosisName; // eslint-disable-line no-param-reassign
    }
    const limits = {};
    Object.entries(values).map(([name, value]) => {
      limits[name] = this.$app.$helper.convertNumber(value);
      return limits;
    });
    state.nutritionLimits = limits;
  },
  setOptionalNutritionValues(state, status) {
    state.useOptionalNutritionValues = status;
  },
  setSavedNutritionLimits(state, data) {
    state.savedNutritionLimits = data;
  },
  setLastSync(state, value) {
    state.lastSync = value;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
