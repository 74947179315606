import i18n from '@/i18n';

const Account = () => import('@/views/User/Account');
const ChangeDiagnosis = () => import('@/views/User/ChangeDiagnosis');
const ChangeEmail = () => import('@/views/User/ChangeEmail');
const Imprint = () => import('@/views/Imprint');
const Terms = () => import('@/views/Terms');
const Privacy = () => import('@/views/Privacy');
const CancelAccount = () => import('@/views/CancelAccount');
const Profile = () => import('@/views/User/Profile');
const Reminders = () => import('@/views/User/Reminders');
const Settings = () => import('@/views/User/Settings');

export default [
  {
    path: '/user',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: {
      requiresAuth: true,
      showNavigation: true,
    },
    children: [
      {
        path: '',
        name: 'profile',
        component: Profile,
        meta: {
          title: i18n.t('user.profile.title'),
        },
      },
      {
        path: 'account',
        name: 'account',
        component: Account,
        meta: {
          title: i18n.t('auth.account.title'),
        },
      },
      {
        path: 'diagnosis',
        name: 'changeDiagnosis',
        component: ChangeDiagnosis,
        meta: {
          title: i18n.t('auth.changeDiagnosis.title'),
        },
      },
      {
        path: 'email',
        name: 'changeEmail',
        component: ChangeEmail,
        meta: {
          title: i18n.t('auth.changeEmail.title'),
        },
      },
      {
        path: 'reminders',
        name: 'reminders',
        component: Reminders,
        meta: {
          title: i18n.t('reminders.title'),
        },
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings,
        meta: {
          title: 'Settings',
        },
      },
      {
        path: '/account/cancel',
        name: 'cancelAccount',
        component: CancelAccount,
        meta: {
          title: i18n.t('cancelAccount.title'),
        },
      },
    ],
  },
  {
    path: '/user/imprint',
    name: 'imprint',
    component: Imprint,
    meta: {
      title: i18n.t('imprint.title'),
    },
  },
  {
    path: '/user/terms',
    name: 'terms',
    component: Terms,
    meta: {
      title: i18n.t('terms.title'),
    },
  },
  {
    path: '/user/privacy',
    name: 'privacy',
    component: Privacy,
    meta: {
      title: i18n.t('privacy.title'),
    },
  },
];
