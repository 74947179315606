/* eslint-disable no-shadow */

import {
  format,
  parseISO,
} from 'date-fns';

const state = () => ({
  templates: [],
  selectedDate: format(new Date(), 'yyyy-MM-dd'),
  templateNutritions: {},
});

const getters = {
  templates: (state) => state.templates,
  selectedDate: (state) => parseISO(state.selectedDate),
  templateNutritions: (state) => state.templateNutritions,
};

const mutations = {
  addTemplate(state, template) {
    const index = state.templates.findIndex((element) => element.date === template.date);
    if (index === -1) {
      state.templates.push(template);
    } else {
      state.templates[index] = template;
    }
  },
  removeTemplate(state, date) {
    const index = state.templates.findIndex((element) => element.date === date);
    state.templates.splice(index, 1);
  },
  setTemplateNutr(state, nutritions) {
    state.templateNutritions = nutritions;
  },
  selectDate(state, date) {
    // Store date without timezone as it's converted to a string and thus use UTC instead of GTM.
    state.selectedDate = format(date, 'yyyy-MM-dd');
  },
  reset() {
    state.templates = null;
    state.selectedDate = null;
    state.templateNutritions = null;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
