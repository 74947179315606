import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/analytics';
import { firestorePlugin } from 'vuefire';
import firebaseConfig from '../firebase.json';
import { version } from '../package.json';

Vue.use(firestorePlugin);

const app = firebase
  .initializeApp({
    apiKey: 'AIzaSyCM9vlfctjHFtpVdom7sNXBBMlfhB4nzZs',
    authDomain: 'loprogramm-9cd6b.firebaseapp.com',
    projectId: 'loprogramm-9cd6b',
    storageBucket: 'loprogramm-9cd6b.appspot.com',
    messagingSenderId: '849027148601',
    appId: '1:849027148601:web:72c8713419777e7ec816e8',
    measurementId: 'G-KMSYEW7F23',
  });
const GCP_LOCATION = 'europe-west3';

const auth = app.auth();
const db = app.firestore();
const functions = app.functions(GCP_LOCATION);
const { FieldValue, FieldPath } = firebase.firestore;

if (['localhost', '127.0.0.1'].includes(window.location.hostname)) {
  db.settings({
    host: `${window.location.hostname}:${firebaseConfig.emulators.firestore.port}`,
    ssl: false,
  });

  functions.useFunctionsEmulator(
    `http://${window.location.hostname}:${firebaseConfig.emulators.functions.port}`,
  );
}

// Send all Authentication service emails in German.
// @see https://firebase.google.com/docs/auth/custom-email-handler#create_the_email_action_handler_page
firebase.auth().languageCode = process.env.VUE_APP_I18N_LOCALE || 'de';
firebase.firestore().enablePersistence();

const firebaseAnalytics = firebase.analytics(app);
const analytics = {
  ...firebaseAnalytics,
  logEvent(eventName, eventParams = {}, options) {
    const params = eventParams;
    params.app_name = (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) ? 'pwa' : 'web';
    params.app_version = version;

    if (window.location.hostname.match(/([0-9]{1,3}\.?){4}|web\.app$|firebaseapp\.com|netlify\.app/)) {
      params.debug_mode = true;
    }
    if (auth.currentUser) {
      const { email } = auth.currentUser;
      if (email.match(/(nutricia\.de|nutricia-metabolics\.de|danone\.com)$/)) {
        params.traffic_type = 'internal';
      } else if (email.match(/(netzstrategen\.com|makers99\.com)$/)) {
        params.debug_mode = true;
      }
    }
    if (params.debug_mode || params.traffic_type) {
      console.log('logEvent', eventName, params, options);
    }

    return firebaseAnalytics.logEvent(eventName, params, options);
  },
};
Vue.prototype.$analytics = analytics;

auth.onAuthStateChanged(async (user) => {
  // Track logged-in users by their ID, since cookies will be lost.
  if (user) {
    analytics.setUserId(user.uid);
    if (window.location.hostname !== 'loprogramm.app') {
      console.log('logEvent onAuthStateChanged', user.uid);
    }
  }
});

export {
  app,
  auth,
  analytics,
  db,
  functions,
  FieldValue,
  FieldPath,
};
