import { Dialog } from 'vant';
import { auth } from '../firebase';
import i18n from '../i18n';

const Login = () => import('@/views/Account/Login');
const Register = () => import('@/views/Account/Register');
const Reset = () => import('@/views/Account/Reset');

export default [
  {
    path: '/account',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
          title: i18n.t('auth.login.title'),
        },
      },
      {
        path: 'logout',
        name: 'logout',
        beforeEnter: (to, from, next) => {
          Dialog.confirm({
            title: i18n.t('auth.logout.dialog.title'),
            message: i18n.t('auth.logout.dialog.message'),
          })
            .then(() => {
              if (to.params && to.params.track) {
                to.params.track();
              }
              auth.signOut();
              this.$store.commit('reset');
              // I'm not sure why we need to redirect twice. Using a named route will throw
              // an error but works as expected still. Leave it for now.
              next();
              next({ name: 'login' });
            })
            .catch(() => {});
        },
      },
      {
        path: 'register',
        name: 'register',
        component: Register,
        meta: {
          title: i18n.t('auth.register.title'),
        },
      },
      {
        path: 'reset',
        name: 'reset',
        component: Reset,
        meta: {
          title: i18n.t('auth.passwordReset.title'),
        },
      },
    ],
  },
];
