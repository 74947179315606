/* eslint-disable no-shadow */

const state = () => ({
  mealId: '',
  mealTitle: 'Name',
  foodItems: [],
  hasUnsavedChanges: false,
});

const getters = {
  mealId: (state) => state.mealId,
  mealTitle: (state) => state.mealTitle,
  foodItems: (state) => state.foodItems,
  hasUnsavedChanges: (state) => state.hasUnsavedChanges,
};

const mutations = {
  setMealId(state, id) {
    state.mealId = id;
  },

  setMealTitle(state, title) {
    state.mealTitle = title;
  },

  setFoodItems(state, values) {
    state.foodItems = values.map((entry) => ({ ...entry, id: entry.id }));
  },
  addFoodItem(state, value) {
    const index = state.foodItems.findIndex((element) => element.id === value.id);
    // eslint-disable-next-line no-param-reassign
    value = { ...value, id: value.id };
    if (index === -1) {
      state.foodItems.push(value);
    } else {
      state.foodItems[index] = value;
    }
    state.hasUnsavedChanges = true;
  },
  removeFoodItem(state, foodId) {
    const index = state.foodItems.findIndex((element) => element.id === foodId);
    state.foodItems.splice(index, 1);
    state.hasUnsavedChanges = true;
  },

  reset(state) {
    state.mealId = '';
    state.mealTitle = 'Name';
    state.foodItems = null;
    state.hasUnsavedChanges = false;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
